<template>
  <div>
    <!-- FIXME: 這裡照理 hidden 要在 subjects assign to data 時就處理乾淨，不該留在 template 層清洗-->
    <div
      v-for="(subject, index) in subjects.filter((x) => {
        return x.hidden == undefined || !x.hidden;
      })"
      :key="`field.${index}.page.${currentPage}`"
      class="field"
    >
      <!-- type: text start -->
      <template v-if="subject.type === 'text'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <input
            class="field__input"
            v-model="form[`${subject.id}`]"
            :class="{ invalid: v$.form[`${subject.id}`].$error }"
            :placeholder="displayPlaceholder(subject)"
            :disabled="subject.disabled"
          />
        </SubjectLayout>
      </template>
      <!-- type: text end -->

      <!-- type: textarea start -->
      <template v-if="subject.type === 'textarea'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <textarea
            class="field__input"
            v-model="form[`${subject.id}`]"
            :class="{ invalid: v$.form[`${subject.id}`].$error }"
            :placeholder="displayPlaceholder(subject)"
            :disabled="subject.disabled"
            v-bind:rows="subject.rows"
          ></textarea>
        </SubjectLayout>
      </template>
      <!-- type: textarea end -->

      <!-- type: select start -->
      <template v-if="subject.type === 'select'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <SharedSelect
            v-model="form[`${subject.id}`]"
            :options="subject.options"
            :hasBorder="false"
            :placeholder="displayPlaceholder(subject)"
            :disabled="subject.disabled"
             :class="{ invalid: v$.form[`${subject.id}`].$error }"
          />
        </SubjectLayout>
      </template>
      <!-- type: select end -->

      <!-- type: radio start -->
      <template v-if="subject.type === 'radio'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <div class="row">
            <div
              class="col-12 col-sm-6 px-2"
              v-for="(option, optionIndex) in subject.options"
              :key="index + '-' + optionIndex"
            >
              <SharedRadioButton
                v-model="form[`${subject.id}`]"
                :id="subject.id + '-' + optionIndex"
                :value="option.value"
                :name="subject.id"
                :disabled="subject.disabled"
              >
                {{ option.text }}
              </SharedRadioButton>
            </div>
          </div>
        </SubjectLayout>
      </template>
      <!-- type: radio end -->

      <!-- type: checkbox start -->
      <template v-if="subject.type === 'checkbox'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <div class="row">
            <div
              class="col-12 col-sm-6 px-2"
              v-for="(option, optionIndex) in subject.options"
              :key="index + '-' + optionIndex"
            >
              <SharedCheckbox
                v-model="form[`${subject.id}`]"
                :id="index + '-' + optionIndex"
                :value="option.value"
                :name="subject.id"
                :disabled="subject.disabled"
              >
                {{ option.text }}
              </SharedCheckbox>
            </div>
          </div>

          <!-- <b-form-checkbox-group
            v-model="form[`${subject.id}`]"
            :stacked="subject.is_vertical"
            :disabled="subject.disabled"
            class="row"
          >
          <div class="col-12 col-sm-6 px-2" :class="form[`${subject.id}`] && form[`${subject.id}`].includes(option.value) && 'active'" v-for="(option,optionIndex) in subject.options" :key="optionIndex">
            <b-form-checkbox :value="option.value">{{ option.text }}</b-form-checkbox>
          </div>
        </b-form-checkbox-group> -->
        </SubjectLayout>
      </template>
      <!-- type: checkbox end -->

      <!-- type: datetime start -->
      <template v-if="subject.type === 'datetime'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <template v-if="subject.format === 'datetime'">
            <AppDateTimePicker
              v-model="form[`${subject.id}`]"
              :placeholder-for-date="displayPlaceholder(subject)"
              :placeholder-for-time="displayPlaceholder(subject) || ' '"
              :has-error="v$.form[`${subject.id}`].$error"
            />
          </template>
          <template v-if="subject.format === 'date'">
            <AppDatePicker
              v-model="form[`${subject.id}`]"
              :placeholder="displayPlaceholder(subject)"
              :has-error="v$.form[`${subject.id}`].$error"
            />
          </template>
          <template v-if="subject.format === 'time'">
            <AppTimePicker
              v-model="form[`${subject.id}`]"
              :placeholder="displayPlaceholder(subject) || ' '"
              :has-error="v$.form[`${subject.id}`].$error"
            />
          </template>
        </SubjectLayout>
      </template>
      <!-- type: datetime end -->

      <!-- type: collection select start -->
      <template v-if="subject.type === 'collection_select'">
        <CollectionSelect
          :subject="subject"
          v-model="form[`${subject.id}`]"
          :placeholder="displayPlaceholder(subject)"
          :validateError="v$.form[`${subject.id}`].$error">
        </CollectionSelect>
      </template>
      <!-- type: collection select end -->

      <!-- type: collection carousel start -->
      <template v-if="subject.type === 'collection_carousel'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <CarouselSelection
            v-model="form[`${subject.id}`]"
            :placeholder="displayPlaceholder(subject, '請選擇')"
            :candidate-fetcher="() => fetchCollectionItemsForCarousel(subject)"
            :value-matcher="matchCandidateForCollectionCarousel">
          </CarouselSelection>
        </SubjectLayout>
      </template>
      <!-- type: collection carousel end -->
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import collectionApi from "@/apis/liff/v2/collection";
import AppDatePicker from "@/components/AppDatePicker.vue";
import AppDateTimePicker from "@/components/AppDateTimePicker.vue";
import AppTimePicker from "@/components/AppTimePicker.vue";
import Collection from "@/models/Collection.model.js";
import SubjectLayout from "./SubjectLayout.vue";
import SharedSelect from "./Select";
import SharedRadioButton from "./Radio";
import SharedCheckbox from "./MultiCheckbox";
import CarouselSelection from "./CarouselSelection";
import CollectionSelect from "./CollectionSelect";
import deepGet from "lodash/get";

export default {
  components: {
    AppDatePicker,
    AppDateTimePicker,
    AppTimePicker,
    SubjectLayout,
    SharedSelect,
    SharedRadioButton,
    SharedCheckbox,
    CarouselSelection,
    CollectionSelect,
  },
  props: {
    inputSubjects: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: Object,
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    inputSubjects: {
      handler: function (val) {
        for (let i = 0; i < val.length; i++) {
          let subject = val[i];

          // 把 subject.config 轉換成 subject 的屬性，這裡是專門用來處理 survey_subjects 的 DB 儲存格式用的
          if (subject.config) {
            let config = subject.config;
            delete subject.config;
            val[i] = {
              ...subject,
              ...config,
            };
          }
        }

        this.subjects = val;
      },
      immediate: true,
    },
    form: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    value: {
      handler: function (val) {
        this.form = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      subjects: [],
      form: this.value || {},
    };
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  validations() {
    let form = {};

    for (let i = 0; i < this.subjects.length; i++) {
      let subject = this.subjects[i];
      form[`${subject.id}`] = subject.required ? { required } : {};
    }

    return {
      form,
    };
  },
  methods: {
    displayPlaceholder(subject, defaultMessage = null) {
      if (subject.placeholder) return subject.placeholder;
      if (defaultMessage !== null) return defaultMessage;
      return "";
    },
    async validate() {
      const result = await this.v$.$validate();

      if (!result) {
        const element = document.getElementById(this.v$.$errors[0].$property);
        element.scrollIntoView({ behavior: "smooth" });
        console.warn("[DynamicForm] Validation failed.");
        return false;
      }

      return true;
    },
    async fetchCollectionItemsForCarousel(subject) { // NOTE: 若 DynamicForm 與 DynamicFormDashboard 需要合併，則將此段邏輯改以 vue provide/inject 方式實作
      try {
        const collection = deepGet(await collectionApi.getCollection(subject.collection_id), 'data.data', null)

        const getValueFromCollectionItemByFieldId = (collectionItem, fieldId) => {
          return deepGet(collectionItem.data.find(field => field.field_id === fieldId), 'value', null)
        }

        const collectionModel = new Collection(collection)

        return collection.items.map((item) => ({
          id: item.id,
          title: getValueFromCollectionItemByFieldId(item, subject[`collection_title`]),
          image: getValueFromCollectionItemByFieldId(item, subject[`collection_image`]),
          description: getValueFromCollectionItemByFieldId(item, subject[`collection_description`]),
          value: {
            type: 'collection_carousel',
            collection_item_id: item.id,
            collection_id: collection.id,
            text: collectionModel.textForOption(item.id),
          },
          placeholderWhenSelected: collectionModel.textForOption(item.id),
        }))
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", `讀取 ${subject.title} 選項時發生錯誤，請重新整理後再試一次`, "error")
        return []
      }
    },
    matchCandidateForCollectionCarousel(candidate, incomingValue) {
      return deepGet(candidate, 'value.collection_id') === deepGet(incomingValue, 'collection_id') &&
        deepGet(candidate, 'value.collection_item_id') === deepGet(incomingValue, 'collection_item_id')
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/components/_fields.scss";

body {
  --liff-button-color: #cccccc;
}

::v-deep .field {
  &__label {
    font-size: 16px;
    color: var(--liff-primary_text_color);
  }
  &__input {
    font-size: 18px;
    background-color: #ffffff;
    color: var(--liff-primary_text_color);
  }

  &__desc {
    font-size: 14px !important;
    color: var(--liff-secondary_text_color);
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }
}

.row {
  margin: 0 -0.5rem;
}
</style>
